<script lang="ts" setup>
import { DefaultInputTemplateProps } from '../constants';
import type { AppInputTemplateProps } from './types';

const props = withDefaults(defineProps<AppInputTemplateProps>(), {
  ...DefaultInputTemplateProps,
  size: 'medium',
});

const emit = defineEmits<{
  onPressed: [];
}>();

const classNames = computed(() => ([
  {
    invalid: props.errMessage,
    focused: props.focused,
    filled: props.isFilled,
  },
  `type-${props.contentType}`,
  `size-${props.size}`,
]));
</script>

<template>
  <div class="app-input-template">
    <div
      class="app-input-template__container"
      :class="classNames"
      @click="emit('onPressed')">
      <div class="app-input-template__inner">
        <div v-if="hasLeftSlot" class="app-input-template__left">
          <slot name="left" />
        </div>
        <div class="app-input-template__content">
          <slot name="content" />
        </div>
        <div v-if="hasRightSlot" class="app-input-template__right">
          <slot name="right" />
        </div>
      </div>
    </div>
    <div
      v-if="errMessage"
      class="app-input-template__err-message">
      {{ errMessage }}
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss" />
